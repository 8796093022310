.become {
  --p-max-width: #{rem(700)};

  &__right {
    place-self: flex-end;
    text-align: right;
  }

  &__list {
    width: auto;
    max-width: calc(#{rem(940)} + #{rem(28)});

    margin: auto;
    padding: 0 rem(30);
  }

  &__card {
    position: relative;

    display: inline-flex;
    flex-flow: column;

    height: max-content;


    padding: rem(27) rem(35);

    vertical-align: top;
    text-align: left;
    break-inside: avoid-column;

    border: 3px solid $tertiary;
    box-shadow: rem(8) rem(8) rem(16) rgba(230, 234, 238, 0.74);
    border-radius: rem(26);

    background: $light;

    &:before {
      position: absolute;
      top: rem(dv(-64, 2));
      left: rem(dv(-64, 2));

      width: rem(64);
      height: rem(64);

      content: '';

      background: transparent url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='32' cy='32' r='30.5' fill='white' stroke='%23FF99B6' stroke-width='3'/%3E%3Cpath d='M27.1893 42.9139L17.4393 33.172C16.8536 32.5867 16.8536 31.6377 17.4393 31.0524L19.5606 28.9328C20.1464 28.3474 21.0962 28.3474 21.6819 28.9328L28.25 35.4954L42.3181 21.439C42.9038 20.8537 43.8536 20.8537 44.4394 21.439L46.5607 23.5585C47.1464 24.1438 47.1464 25.0928 46.5607 25.6781L29.3107 42.914C28.7248 43.4993 27.7751 43.4993 27.1893 42.9139Z' fill='%23FF99B6'/%3E%3C/svg%3E%0A") no-repeat center center/ contain;
    }

    &:not(:last-of-type) {
      margin-bottom: rem(40);
    }
  }

  &__card-head {
    width: 100%;

    font-weight: 600;
    font-size: rem(20);
    line-height: 140%;
  }

  &__card-text {
    margin: rem(11) 0 0;

    font-weight: normal;
    font-size: rem(16);
    line-height: 133%;
  }

  @include min-wide(phonesLg) {
    &__list {
      column-count: 2;
      column-width: auto;
      column-fill: initial;
      column-gap: rem(28);
    }

    &__card {
      margin: rem(28) 0 rem(10) rem(28);

      &:nth-child(4) {
        margin-top: rem(80);
      }
    }
  }
}

