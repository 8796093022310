.find-surrogate {
  --p-margin: #{0 0 rem(54)};

  &__left {
    display: grid;
    grid-gap: rem(38);
    grid-auto-rows: max-content;
    place-items: end;

    max-width: rem(420);

    padding: rem(22) 0 0;

    font-weight: 600;
    font-size: rem(24);
    line-height: 140%;
    text-align: right;
  }

  &__sides {
    display: flex;
    gap: rem(60);
    flex: 1 1;

    width: 100%;
  }

  &__right {
    display: grid;
    grid-gap: $contentGutterX2;
  }

  &__card {
    position: relative;

    padding: rem(32) rem(36);

    background: rgba(255, 255, 255, 0.86);
    box-shadow: rem(-12) rem(14) rem(44) rgba(230, 234, 238, 0.54);
    border-radius: rem(26);

    cursor: default;
  }

  &__card-title {
    font-weight: 600;
    font-size: rem(18);
  }

  &__text {

    margin: rem(10) 0 0;

    font-weight: normal;
    font-size: rem(16);
  }

  @include max-wide(phonesLg) {
    &__sides {
      flex-flow: column;
      gap: rem(48);
    }

    &__left {
      place-self: end;
    }
  }

  @include min-wide(tablet) {
    &__right {
      max-width: rem(653);

      padding: 0 rem(55) 0 0;
    }

    &__card {
      transition: $transition-default;
      transition-property: z-index, transform;

      &:nth-child(1) {
        --find-surrogate-text: #{rem(276)};

        z-index: 1;

        grid-column: 1/4;
        grid-row: 2/4;
      }

      &:nth-child(2) {
        z-index: 0;

        grid-column: 3/6;
        grid-row: 1/3;
      }

      &:nth-child(3) {
        z-index: 0;

        grid-column: 3/6;
        grid-row: 3/5;

        transform: translate(rem(55), 0);
      }

      @include only-mouse {
        &:hover {
          z-index: 2;

          transform: scale(1.14);

          background: $light;
        }
      }
    }

    &__text {
      max-width: var(--find-surrogate-text, rem(290))
    }
  }
}
