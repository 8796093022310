.welcome {
  --container-justify-content: center;
  --container-align-items: flex-end;
  --container-flex-flow: column;
  --container-position: relative;

  --welcome-pic-rotate: 14deg;

  position: relative;

  max-height: rem(940);

  margin: calc(var(--header-height) * -1) 0 0;
  padding: calc(var(--header-height) + rem(178)) 0 29vh;

  color: $light;
  text-align: right;

  opacity: 1;
  animation: none !important;

  &__pre-title, &__text, &__left-img, .caption {
    @extend %glob-animation-to-up;
  }

  &__pre-title {
    margin: 0 0 rem(5);

    font-size: rem(36);
    font-weight: bold;
    line-height: 110%;
  }

  &__text {
    margin: rem(14) 0 rem(38);

    max-width: rem(530);

    font-size: rem(24);
    line-height: 150%;
  }

  &__pic {
    position: absolute;
    bottom: 0;
    left: var(--welcome-pic-left, 50%);
    z-index: -1;

    width: var(--welcome-pic-width, 100%);
    height: var(--welcome-pic-height, 100vh);;

    margin: 0 auto;

    border-radius: 0 0 rem(260) rem(260);
    border: var(--welcome-pic-border, rem(9) solid $light);
    box-shadow: inset 0 rem(4) rem(250) rgba(0, 0, 0, 0.12);

    transform: rotate(var(--welcome-pic-rotate)) translateY(-1vw) translateX(-50%);
    transition: transform;

    animation: smooth-opacity $transition-start-delay $transition-start-duration $transition-function 1 forwards;

    overflow: hidden;

    &:before {
      position: absolute;
      top: -1vh;
      right: -10vh;
      bottom: -5vw;
      left: -10vh;
      height: 150%;

      content: '';

      background: url("../assets/images/welcome.jpg") no-repeat top center /cover;

      transform: rotate(calc(var(--welcome-pic-rotate) * -1)) scale(var(--welcome-pic-scale, 1)) translateY(var(--welcome-pic-translate-y, 10vw));
    }

    &:after {
      @extend %position-full;

      position: absolute;
      z-index: 2;

      content: '';

      background: $primary;
      opacity: 0.89;
    }
  }

  &__left-img {
    --show-block-trn-from: translate(0, -10vh);
    --show-block-trn-to: translate(0, 0);

    position: absolute;
    top: var(--welcome-pic-2-bottom, 25%);
    left: 0;
    z-index: $z-index-step;

    display: flex;

    width: var(--welcome-pic-2-size, rem(380));
    height: var(--welcome-pic-2-size, rem(380));

    background: url("../assets/images/sur-m.png") repeat top center /cover;

    filter: drop-shadow(0 0 rem(46) rgba(#fff, 0.5));
  }

  @media (max-width: 1450px) {
    --welcome-pic-scale: -23vw;
    --welcome-pic-translate-y: 1.2;
  }

  @include max-wide(notebook) {
    --welcome-pic-width: 118%;
    --welcome-pic-left: 55%;
    --welcome-pic-2-bottom: 30%;
  }

  @include max-wide(laptop) {
    --welcome-pic-left: 50%;
    --welcome-pic-2-size: 30vw;
  }

  @include max-wide(phonesLg) {
    --container-align-items: center;
    --welcome-pic-rotate: 0;
    --welcome-pic-border: none;

    --welcome-pic-width: revert;
    --welcome-pic-left: unset;

    text-align: center;

    &__pre-title {
      font-size: rem(28);
    }

    &__left-img {
      display: none;
    }
  }

  @media (max-height: 780px) {
    --welcome-pic-height: 120vh;
  }

  @media (max-height: 868px) and (max-width: 480px) {
    height: 90vh;
    min-height: 560px
  }

  @media (max-height: 570px) {
    min-height: 500px
  }
}
