.choose-language {
  position: fixed;
  top: 50%;
  right: rem(8);
  z-index: $z-index-step + 1;

  display: inline-grid;
  grid-auto-flow: column dense;

  padding: rem(4);

  writing-mode: vertical-lr;

  border-radius: rem(30);
  background: rgba(#fff, 0.8);
  box-shadow: $sh-card;

  opacity: 1;
  transform: translate(0, -50%);

  &__btn {
    padding: rem(20) rem(5);

    font-size: rem(18);
    text-transform: uppercase;
    color: $primary;
    line-height: 1.4;

    border: unset;
    border-radius: inherit;
    background: transparent;

    transition: $transition-default;
    transition-property: background-color, color;

    cursor: pointer;

    &.--is-active {
      color: $light;

      background: $primary;

      pointer-events: none;
    }

    @include only-mouse {
      &:hover {
        color: $light;
        background-color: $primary
      }
    }
  }

  &__to-up {
    position: absolute;
    top: calc(100% + #{rem(8)});
    right: calc(50% - #{dv(rem(36),2)});

    width: rem(36);
    height: rem(36);

    color: $primary;

    border: 1px solid transparent;
    border-radius: 50%;
    background: $light;
    box-shadow: $sh-card;

    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 320'%3E%3Cpath fill='currenCollor' d='M168.5,68.2l148,146.8c4.7,4.7,4.7,12.3,0,17l-19.8,19.8c-4.7,4.7-12.3,4.7-17,0L160,133.3L40.3,251.8 c-4.7,4.7-12.3,4.7-17,0L3.5,232c-4.7-4.7-4.7-12.3,0-17l148-146.8C156.2,63.5,163.8,63.5,168.5,68.2L168.5,68.2z'/%3E%3C/svg%3E") no-repeat center center /40%;

    cursor: pointer;

    @include only-mouse {
      &:hover {
        color: $light;
        border-color: $dark
      }
    }
  }
}
