.get-started {
  --button-background: #{$light};
  --button-color: #{$primary};
  --container-limit: #{rem(600)};

  position: relative;

  margin: 8vh 0;

  color: $light;

  border: rem(2) solid $primary;
  border-left: 0;
  border-right: 0;

  overflow: hidden;

  &:before {
    position: absolute;
    top: rem(10);
    right: 0;
    bottom: rem(10);
    left: 0;
    z-index: -2;

    content: '';

    background: $primary;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;


    transform: translate(-50%, -50%);
    opacity: 0.2;

  }
}
