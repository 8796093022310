.connect-manager {
  --container-align-items: center;
  &__btns {
    display: inline-grid;
    justify-items: center;
    justify-self: baseline;
    grid-gap: rem(17);

    grid-column: 1;
    grid-row: 2;
  }

  &__info {
    display: grid;
    align-items: center;
    justify-content: center;

    grid-column: 2;
    grid-row: 2 span;
  }

  &__pic {
    display: inline-flex;
    width: var(--connect-manager-pic-size, rem(230));
    height: var(--connect-manager-pic-size, rem(230));

    border-radius: 50%;
    border: rem(6) solid $primary;

    filter: drop-shadow($sh-card);
    overflow: hidden;

    img {
      transform: scale(1.05);
    }
  }

  &__label {
    z-index: 1;
    display: inline-flex;

    margin: rem(-32) auto 0;
    padding: rem(7) rem(18);

    font-size: rem(18);
    font-weight: bold;
    color: $light;
    line-height: rem(22);
    letter-spacing: 0.005em;
    white-space: nowrap;

    border-radius: rem(20);
    background: $primary;
  }

  @include max-wide(phonesLg) {
    --connect-manager-pic-size: #{ rem(200 )};
    text-align: center;

    &__btns {
      margin-top: rem(42);
      align-items:center ;
    }
  }

  @include min-wide(phonesLg) {
    &__left {
      display: grid;
      align-items: center;
      grid-gap: 0 rem(24);
    }
  }
}
