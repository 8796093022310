.started {
  --p-max-width: rem(774);
  --caption-margin: #{0 0 rem(48)};

  &__list {
    counter-reset: started-item;
  }

  &__item {
    padding: 0 0 0 rem(50);

    counter-increment: started-item
  }

  &__item-head {
    position: relative;

    display: inline-flex;
    align-items: center;

    color: $light;

    min-height: rem(36);

    margin: 0 0 rem(18) rem(-50);
    padding: rem(4) rem(32) rem(4) 0;

    border-radius: rem(30);

    font-weight: bold;
    font-size: rem(24);
    line-height: 110%;

    background: $primary;

    &:before {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: rem(53);
      min-width: rem(53);
      height: rem(53);

      margin: rem(-10) 0 rem(-10) rem(-7);

      font-family: 'Plaster', cursive;
      font-size: rem(38);
      line-height: 1;
      content: counter(started-item);

      border-radius: 50%;
      background: inherit;
    }
  }

  &__item-text {
    font-size: rem(16);
  }

  @include max-wide(phonesLg) {
    &__item-head {
      color: $primary;

      margin-bottom: rem(6);

      background: unset;
    }

    &__item {
      --p-margin: #{0 0 rem(24)};

      margin-bottom: rem(24);
    }
  }
}
