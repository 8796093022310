//@include keyframes(side-left) {
//  0% {
//    opacity: 0;
//    transform: translate(-30vw, 0);
//  }
//
//  100% {
//    opacity: 1;
//    transform: translate(0vw, 0);
//  }
//}


//@include keyframes(pic-blur) {
//  100% {
//    filter: unset;
//  }
//}

@include keyframes(show-block) {
  0% {
    opacity: 0;
    transform: var(--show-block-trn-from, translate(0, -100%));
  }


  100% {
    opacity: 1;
    transform: var(--show-block-trn-to, unset);
  }
}

@include keyframes(flip) {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(180deg);
  }
}
//
//@include keyframes(pulse-actual) {
//  100% {
//    --pulse-sh: #{rem(20)};
//  }
//}
//
//@include keyframes(animate-stripes) {
//  100% {
//    background-position: -100px 0;
//  }
//}

//@include keyframes(pulse-actual) {
//  50% {
//    box-shadow: 0 0 rem(4) calc(var(--pulse-sh) * var(--pulse-delta)) $secondary,
//    0 0 rem(8) calc(var(--pulse-sh-2) * var(--pulse-delta)) $primary;
//  }
//}

//@include keyframes(pic-shadow) {
//  100% {
//    border: rem(10) solid $light;
//
//    box-shadow: $sh-card,
//    em(10) em(20) 0 0 var(--pic-shadow-cl,$primary),
//    em(16) 0 0 em(8) var(--pic-shadow-cl-2,#131e1d),
//    em(-23) em(-8) 0 0 var(--pic-shadow-cl-3,$secondary),
//    em(-10) em(10) em(14) em(8) var(--pic-shadow-cl-4, #0000004d),
//    em(19) em(-2) em(22) em(10)var(--pic-shadow-cl-5, #00000059);
//  }
//}

@include keyframes(smooth-opacity) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(rotate) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include keyframes(scale-in) {
  from {
    transform: scale(var(--slide-is-scale-from, 0));
  }

  to {
    transform: scale(var(--slide-is-scale-to, 1));
  }
}
